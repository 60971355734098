import React from 'react'
import ReactDOM from 'react-dom'
import Webcam from 'react-webcam'
import './index.css'
import { imageSrc } from './image.js'

const Screenshot = ({ width, screenshot }) => {
  return (
    <div>
      <img width={width} src={screenshot} alt="example" />
    </div>
  )
}
const WebcamCapture2 = ({ width }) => {
  const initialScreenshot = `data:image/jpeg;base64,${imageSrc}`
  const initialWidth = 200

  const webcamRef = React.useRef(null)
  const screenshotRef = React.useRef(initialScreenshot)
  const widthRef = React.useRef(initialWidth)

  const videoConstraints = {
    facingMode: 'environment',
  }

  const [, updateState] = React.useState()
  const forceUpdate = React.useCallback(() => updateState({}), [])

  const sendRequest = React.useCallback(() => {
    screenshotRef.current = webcamRef.current.getScreenshot()
    widthRef.current = widthRef.current * 0.9
    forceUpdate()
  }, [forceUpdate])

  return (
    <div>
      <div>
        <Webcam
          ref={webcamRef}
          audio={false}
          screenshotFormat="image/jpeg"
          width={width}
          videoConstraints={videoConstraints}
        />
      </div>
      <div>
        <button onClick={sendRequest}>Capture photo</button>
      </div>
      {/* {widthRef.current} */}
      <div id="wrapper">
        <div id="first">
          <Screenshot width="100%" screenshot={screenshotRef.current} />
        </div>
        <div id="second">
          <Screenshot
            width="100%"
            screenshot={screenshotRef.current}
            id="second"
          />
        </div>
      </div>
    </div>
  )
}

const App = () => {
  return (
    <div>
      <div>
        <WebcamCapture2 width="100%" />
      </div>
    </div>
  )
}

ReactDOM.render(<App />, document.getElementById('root'))
